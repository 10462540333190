import { useState, useEffect } from "react";
import Select from "react-select";

export default function SelectRange({
  selectedAccount,
  setError,
  setLoading,
  loadAnalytics,
  token,
}) {
  const [rangeData, setRangeData] = useState(null);
  const [fromSelected, setFromSelected] = useState(null);
  const [rangeDataTo, setRangeDataTo] = useState(null);
  const [toSelected, setToSelected] = useState(null);
  const [rangeSelected, setRangeSelected] = useState(null);
  const [selectValues, setSelectValues] = useState(null);
  const [defaultValueSelect, setDefaultValueSelect] = useState(null);

  const getCampaigns = () => {
    fetch(
      "https://analytics-server.videobomb.de/get_campaings/" +
        selectedAccount +
        "?token=" +
        token,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success getCampaigns:", data);
        if (!data?.error) {
          console.log("Success:", data);
          setRangeData(data);

          setLoading(false);
          if (data?.length === 0) {
            setError("No Campaigns found");

            return;
          }
          setFromSelected(0);
          return;
        }
        setError(data.message);

        setLoading(false);
      })
      .catch((error) => {
        setError(JSON.stringify(error));
        setLoading(false);

        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (!rangeData) {
      getCampaigns();
    } else {
      const options = [];
      rangeData.forEach((campaign) => {
        options.push({
          value: campaign.id,
          label:
            campaign.id +
            ", " +
            campaign.name +
            ", Leads:" +
            campaign.leads +
            ", Verschickt:" +
            campaign.verschickt,
        });
      });
      setSelectValues(options);
    }
  }, [rangeData]);

  useEffect(() => {
    if (fromSelected != null && rangeData != null) {
      const newRangeTo = [];
      for (let i = fromSelected + 1; i < rangeData.length; i++) {
        newRangeTo.push(rangeData[i]);
      }

      /* rangeData.filter((campaign) => {
        const selectedSplit = rangeData[fromSelected].datum.split("-");
        const year2 = selectedSplit[2];
        const month2 = selectedSplit[1] - 1;
        const day2 = selectedSplit[0];
        const campaignSplit = campaign.datum.split("-");
        const year1 = campaignSplit[2];
        const month1 = campaignSplit[1] - 1;
        const day1 = campaignSplit[0];

        return new Date(year1, month1, day1) > new Date(year2, month2, day2);
      }); */
      setRangeDataTo(newRangeTo);
      setDefaultValueSelect([]);
      let newRangeSelected;
      if (newRangeTo.length > 0) {
        setToSelected(0);
        newRangeSelected = [fromSelected, fromSelected + 1];
      } else {
        setToSelected(null);
        newRangeSelected = [fromSelected];
      }

      setRangeSelected(newRangeSelected);
    }
  }, [fromSelected, rangeData]);

  useEffect(() => {
    if (toSelected != null && rangeDataTo?.length > 0) {
      const newRangeSelected = [];

      for (let i = fromSelected; i <= toSelected; i++) {
        newRangeSelected.push(i);
      }

      setRangeSelected(newRangeSelected);
    }
  }, [toSelected]);

  useEffect(() => {
    if (rangeSelected?.length > 0) {
      const newSelectValues = [];
      for (let i = 0; i < rangeSelected.length; i++) {
        newSelectValues.push(selectValues[rangeSelected[i]]);
      }
      setDefaultValueSelect(newSelectValues);
      console.log("rangeSelectedggggggggg", newSelectValues);
    }
  }, [rangeSelected]);

  return (
    <div className="select-range-container">
      <h1> Select Range</h1>
      {rangeData ? (
        <div style={{ display: "flex", gap: "25px" }}>
          <div>
            <div>Von:</div>
            <select
              onChange={(e) => {
                setFromSelected(parseInt(e.target.value));
              }}
            >
              {rangeData?.map((campaign, index) => (
                <option key={campaign.id} value={index}>
                  {campaign.id}, {campaign.name}, Leads:{campaign.leads},
                  Verschickt:
                  {campaign.verschickt}
                </option>
              ))}
            </select>
          </div>

          {rangeDataTo?.length > 0 && (
            <div>
              <div>Bis:</div>
              <select
                onChange={(e) => {
                  setToSelected(parseInt(e.target.value));
                }}
              >
                {rangeDataTo?.map((campaign, index) => (
                  <option key={campaign.id} value={index}>
                    {campaign.id}, {campaign.name}, Leads:{campaign.leads},
                    Verschickt:
                    {campaign.verschickt}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <div>Einzelauswahl:</div>
            <div className="multiselect">
              <Select
                defaultValue={defaultValueSelect}
                options={selectValues}
                isMulti
                onChange={(e) => {
                  console.log("e MultiSelect", e);
                  const newRangeSelected = [];
                  for (let i = 0; i < e.length; i++) {
                    const index = rangeData.findIndex(
                      (campaign) => campaign.id === e[i].value
                    );
                    newRangeSelected.push(index);
                  }
                  setRangeSelected(newRangeSelected);
                }}
              ></Select>
            </div>
          </div>
        </div>
      ) : (
        <div> Loading </div>
      )}
      <button
        onClick={() => {
          if (rangeSelected?.length > 0) {
            const idArray = [];
            for (let i = 0; i < rangeSelected.length; i++) {
              idArray.push(rangeData[rangeSelected[i]].id);
            }
            loadAnalytics(idArray);
          }
        }}
      >
        Daten Laden
      </button>
    </div>
  );
}
