
const dummyData = [
    {
        "id": 0,
        "name": "Name_0",
        "letters_sent": 45,
        "openings_total": 8,
        "video_link": "http://example.com/video_0",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_0_0",
                "openings": 49
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_0_1",
                "openings": 54
            }
        ]
    },
    {
        "id": 1,
        "name": "Name_1",
        "letters_sent": 438,
        "openings_total": 121,
        "video_link": "http://example.com/video_1",
        "documents": [

            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_1_2",
                "openings": 8
            }
        ]
    },
    {
        "id": 2,
        "name": "Name_2",
        "letters_sent": 465,
        "openings_total": 135,
        "video_link": "http://example.com/video_2",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_2_0",
                "openings": 94
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_2_1",
                "openings": 48
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_2_2",
                "openings": 59
            }
        ]
    },
    {
        "id": 3,
        "name": "Name_3",
        "letters_sent": 431,
        "openings_total": 292,
        "video_link": "http://example.com/video_3",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_3_0",
                "openings": 45
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_3_1",
                "openings": 91
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_3_2",
                "openings": 49
            }
        ]
    },
    {
        "id": 4,
        "name": "Name_4",
        "letters_sent": 339,
        "openings_total": 57,
        "video_link": "http://example.com/video_4",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_4_0",
                "openings": 97
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_4_1",
                "openings": 84
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_4_2",
                "openings": 61
            }
        ]
    },
    {
        "id": 5,
        "name": "Name_5",
        "letters_sent": 399,
        "openings_total": 9,
        "video_link": "http://example.com/video_5",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_5_0",
                "openings": 57
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_5_1",
                "openings": 89
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_5_2",
                "openings": 15
            }
        ]
    },
    {
        "id": 6,
        "name": "Name_6",
        "letters_sent": 445,
        "openings_total": 11,
        "video_link": "http://example.com/video_6",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_6_0",
                "openings": 25
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_6_1",
                "openings": 28
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_6_2",
                "openings": 16
            }
        ]
    },
    {
        "id": 7,
        "name": "Name_7",
        "letters_sent": 127,
        "openings_total": 15,
        "video_link": "http://example.com/video_7",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_7_0",
                "openings": 92
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_7_1",
                "openings": 68
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_7_2",
                "openings": 7
            }
        ]
    },
    {
        "id": 8,
        "name": "Name_8",
        "letters_sent": 379,
        "openings_total": 113,
        "video_link": "http://example.com/video_8",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_8_0",
                "openings": 47
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_8_1",
                "openings": 43
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_8_2",
                "openings": 67
            }
        ]
    },
    {
        "id": 9,
        "name": "Name_9",
        "letters_sent": 18,
        "openings_total": 74,
        "video_link": "http://example.com/video_9",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_9_0",
                "openings": 87
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_9_1",
                "openings": 63
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_9_2",
                "openings": 22
            }
        ]
    },
    {
        "id": 10,
        "name": "Name_10",
        "letters_sent": 487,
        "openings_total": 299,
        "video_link": "http://example.com/video_10",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_10_0",
                "openings": 87
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_10_1",
                "openings": 28
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_10_2",
                "openings": 87
            }
        ]
    },
    {
        "id": 11,
        "name": "Name_11",
        "letters_sent": 9,
        "openings_total": 272,
        "video_link": "http://example.com/video_11",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_11_0",
                "openings": 91
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_11_1",
                "openings": 76
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_11_2",
                "openings": 3
            }
        ]
    },
    {
        "id": 12,
        "name": "Name_12",
        "letters_sent": 249,
        "openings_total": 100,
        "video_link": "http://example.com/video_12",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_12_0",
                "openings": 70
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_12_1",
                "openings": 24
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_12_2",
                "openings": 77
            }
        ]
    },
    {
        "id": 13,
        "name": "Name_13",
        "letters_sent": 66,
        "openings_total": 168,
        "video_link": "http://example.com/video_13",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_13_0",
                "openings": 77
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_13_1",
                "openings": 58
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_13_2",
                "openings": 96
            }
        ]
    },
    {
        "id": 14,
        "name": "Name_14",
        "letters_sent": 449,
        "openings_total": 266,
        "video_link": "http://example.com/video_14",
        "documents": [
            {
                "id": 0,
                "name": "Document_0",
                "link": "http://example.com/doc_14_0",
                "openings": 57
            },
            {
                "id": 1,
                "name": "Document_1",
                "link": "http://example.com/doc_14_1",
                "openings": 55
            },
            {
                "id": 2,
                "name": "Document_2",
                "link": "http://example.com/doc_14_2",
                "openings": 67
            }
        ]
    }
]

export default dummyData;