export default function SelectAccounts({ accounts, setSelectedAccount }) {
  return accounts ? (
    <select
      onChange={(e) => {
        setSelectedAccount(e.target.value);
      }}
    >
      {accounts?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.id}, {item.user}
        </option>
      ))}
    </select>
  ) : (
    <div>no accounts</div>
  );
}
