import { useState } from "react";

export default function TabComponent({ tabs }) {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div tabIndex={-1} id="tab-component" className="tab-component">
      Tabs
    </div>
  );
}
