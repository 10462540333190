
import './App.css';
import { useState } from 'react';
import LoginPage from './Login';
import MainComponent from './MainComponent';

function App() {

  const [token, setToken] = useState("test$43843fjaoiawguz3=");

  return (
    <div className="App">
      {!token ? <LoginPage setToken={setToken} /> : <MainComponent token={token} />}
    </div>
  );
}

export default App;
