import React, { useState, useEffect } from "react";
import SelectAccounts from "./SelectAccounts";
import SelectRange from "./SelectRange";
import TabComponent from "./TabComponent";
import dummyData from "./dummyAnalyticsData";
import Table from "./Table";

export default function MainComponent({ token, setToken }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState(null);

  const [selectedAccount, setSelectedAccount] = useState(null);

  const getAccounts = () => {
    fetch("https://analytics-server.videobomb.de/get_accounts?token=" + token, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        if (!data?.error) {
          console.log("Success:", data);
          setData(data);
          setLoading(false);
          if (data?.accounts?.length === 0) {
            setError("No accounts found");
            setSelectedAccount(null);
            return;
          }
          setSelectedAccount(data?.accounts?.[0].id);
          return;
        }
        setError(data.message);
        setSelectedAccount(null);
        setLoading(false);
      })
      .catch((error) => {
        setError(JSON.stringify(error));
        setLoading(false);
        setSelectedAccount(null);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (!data) {
      getAccounts();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const loadAnalytics = (rangeSelected) => {
    console.log("rangeSelected", rangeSelected);
    fetch("https://analytics-server.videobomb.de/analytics?token=" + token, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campain_list: rangeSelected,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        if (!data?.error) {
          console.log("Success Analytics:", data);
          setTableData(data);
          /*   setData(data);
          setLoading(false);
          if (data?.accounts?.length === 0) {
            setError("No accounts found");
            setSelectedAccount(null);
            return;
          }
          setSelectedAccount(data?.accounts?.[0].id);
          return; */
        }
        /*  setError(data.message);
        setSelectedAccount(null);
        setLoading(false); */
      })
      .catch((error) => {
        /*  setError(JSON.stringify(error));
        setLoading(false);
        setSelectedAccount(null); */
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <h1> Main Component</h1>
      {data && (
        <SelectAccounts
          accounts={data?.accounts}
          setSelectedAccount={setSelectedAccount}
        />
      )}
      {selectedAccount && (
        <SelectRange
          key={selectedAccount}
          selectedAccount={selectedAccount}
          setError={setError}
          setLoading={setLoading}
          loadAnalytics={loadAnalytics}
          token={token}
        />
      )}
      <TabComponent tabs={""} />

      {tableData && <Table tableData={tableData} />}
      {loading && (
        <div className="loading">
          <h1>Loading...</h1>
        </div>
      )}
      {error && (
        <div className="error">
          <h1>{error}</h1>
        </div>
      )}
    </div>
  );
}
