import React, { useState } from "react";
import videoIcon from "./icons/videoIcon.svg";
import documentIcon from "./icons/documentIcon.svg";
import mailIcon from "./icons/mailIcon.svg";

export default function Table({ tableData }) {
  const [showJsonData, setShowJsonData] = useState(false);
  return (
    <div className="">
      <h1
        onClick={() => {
          setShowJsonData(!showJsonData);
        }}
      >
        Table
      </h1>
      {showJsonData && <pre>{JSON.stringify(tableData, null, 2)}</pre>}
      <div className="table">
        {tableData?.map((row, index) => {
          const openingsTotalRatio =
            row.openings_total / (row.letters_sent / 100);
          return (
            <div key={row.id} className="table-row">
              <div className="general-information">
                <div className="table-row-id" title="ID">
                  {row.id}
                </div>
                <div className="table-row-name" title={row.name}>
                  {row.name}
                </div>
                <div className="table-row-letters-send" title="Letters Sent">
                  {row.letters_sent}
                  <img src={mailIcon} alt="" />
                </div>

                <div
                  className={
                    "table-row-openings-total " + openingsTotalRatio > 10
                      ? "colorGreen"
                      : openingsTotalRatio > 5
                      ? "colorYellow"
                      : "colorRed"
                  }
                >
                  <div title="Openings Total">{row.openings_total}</div>
                  <div title="Openings Ratio">
                    {openingsTotalRatio.toFixed(2)}%
                  </div>
                </div>
                <div className="video-link" title="Open Video">
                  <a href={row.video_link}>
                    <img src={videoIcon} alt="" />
                  </a>
                </div>
              </div>

              <div className="document-container">
                {row.documents
                  ?.sort((a, b) => {
                    return b.openings - a.openings;
                  })
                  ?.map((document, index) => {
                    const documentOpeningsRatio =
                      document.openings / (row.openings_total / 100);

                    return (
                      <div key={index} className="document-entry">
                        <div
                          className={
                            "table-row-document-openings " +
                            (documentOpeningsRatio > 10
                              ? "colorGreen"
                              : documentOpeningsRatio > 5
                              ? "colorYellow"
                              : "colorRed")
                          }
                        >
                          <div title="Documents Openings ">
                            {" "}
                            {document.openings} /
                          </div>
                          <div title="Documents Openings Ratio">
                            {" "}
                            {documentOpeningsRatio.toFixed(2)}%
                          </div>
                        </div>
                        <div
                          title="Open Document"
                          className="document-link-container"
                        >
                          <a href={document.link} title={document.name}>
                            <img src={documentIcon} alt="" />
                          </a>
                          <div className="document-name" title={document.name}>
                            {document.name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
